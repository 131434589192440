import React from 'react';
import './Section.css';

const Section = ({ id, title, height, children }) => {
  const sectionStyle = {
    height: height ? `${height}px` : 'auto',
  }

  return (
    <div id={id} className="section" style={sectionStyle}>
      {title && <h4>{title}</h4>}
      {children}
    </div>
  );
};

export default Section;
