import React from 'react';
import Section from '../../Section/Section';
import homeImage from '../../../assets/soon-home.png';
import './Home.css';

const Home = () => {
  return (
    <Section id="home" title="" className="home" height={"400"}>
        <div className="content">
          <div className="image-container">
            <img src={homeImage} alt="Home" />
            <p>SOON is more than just a token; it's a whole philosophy! It reminds us that even the grandest promises can turn out to be empty words. But no matter what happens, we can always laugh and wait for real changes, even if they come "soon" (eventually).</p>
          </div>
        </div>
    </Section>
  );
};

export default Home;  
