import React from 'react';
import './Footer.css';
import leftImage from '../../assets/wen-footer.png';
import dexscreener from '../../assets/dex-screener-logo-png-footer.png';
import twitter from '../../assets/twitter-footer.png';
import telegram from '../../assets/telegram-footer.png';
import rightImage from '../../assets/eventually-footer.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-separator"></div>
      <div className="footer-content">
        <p>At SOON, we believe that everything amazing is just around the corner.</p>
        <p>Stay tuned and keep smiling, because the best is always Coming Soon!</p>
      </div>
      <div className="footer-images">
        <img src={leftImage} alt="Left" className="left-image" />
        <div className="social-icons">
          <a href="https://dexscreener.com/solana/6VUnbRQ6csF2oYbaGxJ2rrY2jXBXmvCebAjKuxZoyawF" target="_blank" rel="noopener noreferrer">
            <img src={dexscreener} alt="Dexscreener" />
          </a>
          <a href="https://x.com/s00ntoken" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Twitter" />
          </a>
          <a href="https://t.me/tokensooneth" target="_blank" rel="noopener noreferrer">
            <img src={telegram} alt="Telegram" />
          </a>
        </div>
        <img src={rightImage} alt="Right" className="right-image" />
      </div>
      <div className="footer-bottom-text">
        <p>© 2024 by SOON. All rights reserved!</p>
      </div>
    </div>
  );
};

export default Footer;
