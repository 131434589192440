import React from 'react';
import Section from '../../Section/Section';
import hitPepeSoon from '../../../assets/hit-pepe-soon.png';
import soonTokenomics from '../../../assets/soon-tokenomics.png';
import './Tokenomics.css';

const Tokenomics = () => {
  return (
    <Section id="tokenomics" title="Tokenomics" height={"480"}>
      <div className="images-container">
        <img src={hitPepeSoon} alt="Hit Pepe Soon" className="hit-pepe-soon" />
          <p className="middle-text">
            No investors, no vesting, all to community<br/>
            Total supply: 100.000.000 SOON<br/>
            LP tokens are burnt + contract ownership is renounced<br/>
          </p>
        <img src={soonTokenomics} alt="Soon Tokenomics" className="soon-tokenomics" />
      </div>
    </Section>
  );
};

export default Tokenomics;
