import React from 'react';
import { Link } from 'react-scroll';
import './Navbar.css';
import logoImage from '../../assets/logo.jpg';
import dexscreener from '../../assets/dexscreener-logo.png';
import twitter from '../../assets/twitter-logo.png';
import telegram from '../../assets/telegram-logo.png';

const Navbar = () => {
  const handleIconClick = (url) => {
    window.open(url, '_blank'); // Открываем ссылку в новой вкладке
  };

  return (
    <nav className="navbar">
       <div className="logo">
          <img src={logoImage} alt="Logo" />
      </div>
      <div className="nav-links-container">
        <div className="nav-links">
          <Link className="nav-link" to="home" smooth={true} duration={500}>Home</Link>
          <Link className="nav-link" to="about" smooth={true} duration={500}>About</Link>
          <Link className="nav-link" to="how-to-buy" smooth={true} duration={500}>How to Buy</Link>
          <Link className="nav-link" to="roadmap" smooth={true} duration={500}>Roadmap</Link>
          <Link className="nav-link" to="tokenomics" smooth={true} duration={500}>Tokenomics</Link>
        </div>
      </div>
      <div className="icons">
          <img src={dexscreener} alt="Icon 1" onClick={() => handleIconClick('https://dexscreener.com/solana/6VUnbRQ6csF2oYbaGxJ2rrY2jXBXmvCebAjKuxZoyawF')} />
          <img src={twitter} alt="Icon 2" onClick={() => handleIconClick('https://x.com/s00ntoken')} />
          <img src={telegram} alt="Icon 3" onClick={() => handleIconClick('https://t.me/tokensooneth')} />
        </div>
    </nav>
  );
};

export default Navbar;
