import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './components/sections/Home/Home';
import About from './components/sections/About/About';
import HowToBuy from './components/sections/HowToBuy/HowToBuy';
import Roadmap from './components/sections/Roadmap/Roadmap';
import Tokenomics from './components/sections/Tokenomics/Tokenomics';
import Footer from './components/Footer/Footer';
import './App.css';

const App = () => {
  return (
    <div>
      <Navbar />
      <div className="content">
        <Home />
        <About />
        <HowToBuy />
        <Roadmap />
        <Tokenomics />
      </div>
      <Footer />
    </div>
  );
};

export default App;