import React from 'react';
import Section from '../../Section/Section';
import './Roadmap.css';
import babySoon from'../../../assets/baby-roadmap.png';

const Roadmap = () => {
  return (
    <Section id="roadmap" title="Roadmap" height={"1200"}>
      <div className="random-section">
        <div className="paragraph p1">
          <p>Phase 1: Initial Launch</p>
          <ul>
            <li>Token Creation: Done ✅</li>
            <li>Website Launch: Done ✅</li>
            <li>Community Building: In Progress 🔄</li>
            <li>Everything Else: Coming Soon</li>
          </ul>
        </div>
        <div className="paragraph p2">
          <p>Phase 2: Development</p>
          <ul>
            <li>New Features: Coming Soon</li>
            <li>Partnership: Coming Soon</li>
            <li>Token Utility Expansion: Coming Soon?</li>
            <li>Mobile App: Coming Soon</li>
          </ul>
        </div>
        <div className="paragraph p3">
          <p>Phase 3: Marketing</p>
          <ul>
            <li>Massive Ad Campaigns: Coming Soon</li>
            <li>Influencer Collaborations: Coming Soon</li>
            <li>Viral Meme Contests: Coming Soon™</li>
          </ul>
        </div>
        <div className="paragraph p4">
          <p>Phase 4: Growth</p>
          <ul>
            <li>Exchange Listings: Coming Soon</li>
            <li>Staking Programs: Coming Soon</li>
            <li>Ecosystem Integration: Coming Soon</li>
          </ul>
        </div>
        <div className="image-container-roadmap">
          <img id="baby-soon" src={babySoon} alt="Baby soon" />
        </div>
        <div className="paragraph p5">
          <p>Phase 5: Moon Mission</p>
          <ul>
            <li>Price to the Moon: Coming Soon</li>
            <li>Binance: Coming Soon</li>
          </ul>
        </div>
      </div>
    </Section>
  );
};

export default Roadmap;
