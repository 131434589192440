import React from 'react';
import Section from '../../Section/Section';
import './About.css';
import sqwImage from '../../../assets/Sqw.jpg';
import thinkAboutIt from '../../../assets/think-about-it.jpg';
import soonAbout from '../../../assets/soon-about.png';

const About = () => {
  return (
    <Section id="about" title="About" height={"1300"}>
      <p id="about-p">SOON is a satirical token that laughs with us at the never-ending promises of grand plans and achievements that are always "in the works." After all, what could be funnier than another "launch next week" that gets delayed for months or even years?</p>
      <div className="about-content">
          <div className="sqw-image-container">
              <img id="sqw-image" src={sqwImage} alt="Home" />
              <p id="about-sqw">Why $OON?</p>
          </div>
          <div className="think-about-it-container">
            <p id="about-think-about-it">Smiles Guaranteed: Every mention of SOON brings a smile and a nod of understanding from crypto enthusiasts. Who among us hasn't heard our favorite project say, "Coming soon!"?</p>
            <img id="think-about-it-image" src={thinkAboutIt} alt="Home"/>
          </div>
          <img id="soon-about-image" src={soonAbout} alt="Soon About" />
          <div className="about-under-container">
            <div className="au-container-right">
              <p id="about-under-think-about-it">United in Waiting: SOON brings together everyone who's ever sat in front of their screen, waiting for promised updates and real progress, turning disappointment into a reason for laughter and jokes.</p>
            </div>
            <div className="au-container-left">
              <p id="under-about-under-think-about-it">Catcher of Empty Promises: This token is the perfect tool for sarcastic comments and memes, helping to mock the latest "genius" plan.</p>
            </div>
          </div>
        </div>
    </Section>
  );
};

export default About;
