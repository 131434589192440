import React from 'react';
import Section from '../../Section/Section';
import howToBuyImg from '../../../assets/eyes.png';
import dexscreenerImg from '../../../assets/dexscreener-logo.png';

import './HowToBuy.css';

const HowToBuy = () => {
  return (
    <Section id="how-to-buy" title="How to Buy" height={350}>
      <div id="how-to-buy-content">
        <div id="image-container-how-to-buy">
          <img id='eyes-image' src={howToBuyImg} alt='How to buy'/>
        </div>
        <div id="text-container">
          <p>Contract: 6VUnbRQ6csF2oYbaGxJ2rrY2jXBXmvCebAjKuxZoyawF</p>
          <div id="link-container">
            <a href="https://dexscreener.com/solana/6VUnbRQ6csF2oYbaGxJ2rrY2jXBXmvCebAjKuxZoyawF" id="youtube-link" target="_blank" rel="noopener noreferrer">
              Dexscreener
              <img src={dexscreenerImg} alt="YouTube" id="youtube-icon"/>
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowToBuy;
